module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"有限会社山口SB（やまぐちエスビー）","short_name":"山口SB","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"static/favicon.png","icons":[{"src":"static/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"static/maskable-icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"static/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/maskable-icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f5499f72e8dda742f3e6b00317600b8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-L2E593Q72G"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
